import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService, SystemDataService } from '../../services';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
    systemDataService = inject(SystemDataService);
    configService = inject(ConfigService);

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (
            req.url.includes(this.configService.config.apiDomain) &&
            this.systemDataService.tenant$.value
        ) {
            const auth = req.clone({
                headers: req.headers.set(
                    'x-clmi-tenant',
                    this.systemDataService.tenant$.value.code
                ),
            });

            return next.handle(auth);
        }

        return next.handle(req);
    }
}
