import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import {
    Router,
    RouterLink,
    RouterLinkActive,
    RouterLinkWithHref,
    RouterOutlet,
} from '@angular/router';

import { AuthService } from '@auth0/auth0-angular';

import { KaminoTenantComponent } from '@wdx/kamino/ui';
import { WdxAppContentModule } from '@wdx/shared/components/wdx-app-content';
import { WdxAppHeaderModule } from '@wdx/shared/components/wdx-app-header';
import { WdxAppShellModule } from '@wdx/shared/components/wdx-app-shell';
import { WdxAppSidebarModule } from '@wdx/shared/components/wdx-app-sidebar';
import { Avatar } from '@wdx/shared/components/wdx-avatar';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxNavModule } from '@wdx/shared/components/wdx-nav';
import { WdxUserMenuComponent } from '@wdx/shared/components/wdx-user-menu';

import { PushModule } from '@ngrx/component';
import {
    ActiveFeatureFacadeService,
    ExportService,
    ITenant,
    MetaDataService,
    SystemDataService,
} from '@wdx/kamino/api-service';
import { KaminoFileUploaderService } from '@wdx/kamino/features/file-uploader';
import { HOME, ICONS } from '@wdx/kamino/utils/constants';
import { KaminoRecentRecordsService } from '@wdx/kamino/utils/services';
import {
    WdxButtonModule,
    WdxButtonSize,
    WdxButtonStyle,
} from '@wdx/shared/components/wdx-button';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { WdxIsLoadingModule } from '@wdx/shared/components/wdx-is-loading';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import { DOWNLOAD, WdxAppThemeService, iThemeSetting } from '@wdx/shared/utils';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Component({
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        RouterOutlet,
        RouterLinkWithHref,
        RouterLink,
        RouterLinkActive,
        WdxAppHeaderModule,
        WdxAppContentModule,
        WdxAppSidebarModule,
        WdxAppShellModule,
        WdxButtonModule,
        WdxIconModule,
        WdxIconContainerModule,
        WdxSpinnerModule,
        WdxNavModule,
        WdxUserMenuComponent,
        KaminoTenantComponent,
        PushModule,
        WdxIsLoadingModule,
    ],
    selector: 'ops-shell',
    templateUrl: './app-shell.component.html',
    styleUrls: ['./app-shell.component.scss'],
})
export class AppShellComponent implements OnInit {
    collapsed = false;
    downloading$ = new BehaviorSubject(false);
    readonly ICONS = ICONS;
    readonly HOME = HOME;

    private systemDataService = inject(SystemDataService);
    private exportService = inject(ExportService);
    private metaDataService = inject(MetaDataService);
    private authService = inject(AuthService);
    private fileUploaderService = inject(KaminoFileUploaderService);
    private wdxAppThemeService = inject(WdxAppThemeService);
    private recentRecordsService = inject(KaminoRecentRecordsService);
    private router = inject(Router);
    public activeFeatureFacadeService = inject(ActiveFeatureFacadeService);

    whoAmI = this.systemDataService.whoAmI$?.value;
    avatar: Avatar = {
        avatarUrl: this.whoAmI?.avatarUrl,
        text: `${this.whoAmI?.firstName} ${this.whoAmI?.lastName}`,
    };
    environment = this.whoAmI?.environment;
    tenants$ = this.systemDataService.tenants$;
    metadata$ = this.metaDataService.metadata$;
    readonly WDX_BUTTON_SIZE = WdxButtonSize;
    readonly WDX_BUTTON_STYLE = WdxButtonStyle;

    get tenant(): ITenant {
        return this.systemDataService.tenant$.value as ITenant;
    }
    get themeInfo(): { icon: string; name: string } {
        return this.wdxAppThemeService.themeSetting === iThemeSetting.Light
            ? { icon: 'moon', name: 'dark' }
            : { icon: 'sun', name: 'light' };
    }

    ngOnInit(): void {
        this.recentRecordsService.setTenant(this.tenant.code);
    }

    onCollapsed(): void {
        this.collapsed = !this.collapsed;
    }

    wdxOnLogout(): void {
        this.authService.logout();
    }

    exportZip(isDownloading: boolean | undefined): void {
        if (!isDownloading) {
            this.downloading$.next(true);
            this.exportService.zip().subscribe({
                next: (data) => {
                    DOWNLOAD(
                        `export-${this.environment}-${this.tenant?.code}-${
                            this.whoAmI?.firstName
                        }-${this.whoAmI?.lastName}-${Date.now()}`,
                        data
                    );
                    this.downloading$.next(false);
                },
                error: () => {
                    this.downloading$.next(false);
                },
            });
        }
    }

    openImport(): void {
        this.fileUploaderService.open();
    }

    onTenantSwitch(tenant: ITenant) {
        const featureCode = this.activeFeatureFacadeService.featureCode;
        const currentUrl = this.router.url;

        let navigationPath = [tenant.code, HOME];

        if (featureCode && !currentUrl.includes(HOME)) {
            navigationPath = [tenant.code, featureCode];
        }

        this.router.navigate(navigationPath).then(() => {
            window.location.reload();
        });
    }

    switchTheme(): void {
        if (this.wdxAppThemeService.themeSetting === iThemeSetting.Light) {
            this.wdxAppThemeService.setToDark();
        } else {
            this.wdxAppThemeService.setToLight();
        }
    }
}
