import { Injectable, inject } from '@angular/core';
import { LocalStorageService } from '@wdx/shared/utils';
import { BehaviorSubject } from 'rxjs';
import { ITenant, IWhoAmI } from './system.service.interface';

const TENANT = 'tenant';

@Injectable({
    providedIn: 'root',
})
export class SystemDataService {
    private localStorageService = inject(LocalStorageService);

    whoAmI$ = new BehaviorSubject<IWhoAmI | null>(null);
    tenants$ = new BehaviorSubject<ITenant[]>([]);
    tenant$ = new BehaviorSubject<ITenant | null>(null);
    isLoading$ = new BehaviorSubject(true);

    setTenantData(tenantCode?: string): { tenant: string; redirect: boolean } {
        let redirect = false;

        let tenantCodeUpdate: string | null | undefined = tenantCode;

        if (!tenantCodeUpdate) {
            tenantCodeUpdate = this.localStorageService.getStringKey(TENANT);
            redirect = true;
        }

        this.localStorageService.setStringKey(TENANT, tenantCodeUpdate);

        const TENANTS = this.tenants$.value;
        let tenant = TENANTS?.find(
            (tenant) => tenant.code === tenantCodeUpdate
        ) as ITenant;

        if (!tenant) {
            tenant = TENANTS?.[0];
            redirect = true;
        }

        this.tenant$.next(tenant);

        return { tenant: tenant?.code, redirect };
    }
}
