<div
    data-cy="app-sidebar-nav"
    class="side-nav d-flex flex-column flex-shrink-0 h-100 overflow-hidden bg-body"
    [class.side-nav--collapsed]="collapsed"
>
    <div class="bg-primary-dark">
        <wdx-icon-button
            data-cy="app-sidebar-toggle-button"
            class="app-sidebar-toggle"
            size="lg"
            (wdxOnClick)="toggle()"
        >
            <wdx-icon-container size="lg" class="text-primary-dark-contrast">
                <wdx-icon icon="bars"></wdx-icon>
            </wdx-icon-container>
        </wdx-icon-button>
    </div>

    <ng-content></ng-content>
</div>
