<ng-container *ngIf="isAppReady$ | ngrxPush; else loading">
    <router-outlet></router-outlet>
</ng-container>

<ng-template #loading>
    <div
        class="bg-body position-fixed h-100 w-100 d-flex align-items-center justify-content-center"
    >
        <wdx-spinner></wdx-spinner>
    </div>
</ng-template>

<wdx-toast></wdx-toast>
