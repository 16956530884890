import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxAppContentComponent } from './wdx-app-content.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxAppContentComponent],
    exports: [WdxAppContentComponent],
})
export class WdxAppContentModule {}
