import { Injectable, inject } from '@angular/core';
import { EVENT, FILE } from '@wdx/kamino/utils/constants';
import { LocalStorageService } from '@wdx/shared/utils';
import { IRecentRecord } from './recent-records.interface';

@Injectable({
    providedIn: 'root',
})
export class KaminoRecentRecordsService {
    private localStorageService = inject(LocalStorageService);

    private _tenant = '';

    get tenant(): string {
        return this._tenant;
    }

    /**
     * Gets the event records from local storage
     */
    get events(): IRecentRecord[] {
        return JSON.parse(
            this.localStorageService.getStringKey(
                `${this.tenant}-${EVENT}`
            ) as string
        );
    }

    /**
     * Gets the file records from local storage
     */
    get files(): IRecentRecord[] {
        return JSON.parse(
            this.localStorageService.getStringKey(
                `${this.tenant}-${FILE}`
            ) as string
        );
    }

    /**
     *
     * @param {string} key - This will get the tenant prepended to create the key
     * @param {IRecentRecord} recent - This is the data that needs to be stored against the key in local storage
     */
    setRecentRecord(key: string, recent: IRecentRecord): void {
        const KEY = `${this.tenant}-${key}`;
        const KEY_DATA = this.localStorageService.getStringKey(KEY);
        let event = [{ ...recent, date: new Date().toISOString() }];

        if (KEY_DATA) {
            const DATA = JSON.parse(KEY_DATA);
            event = [...event, ...DATA];
        }

        this.localStorageService.setStringKey(KEY, JSON.stringify([...event]));
    }

    /**
     *
     * @param {string} tenant - Set the tenant to be used
     */
    setTenant(tenant: string) {
        this._tenant = tenant;
    }
}
