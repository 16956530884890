import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
    selector: 'wdx-nav-wrapper',
    templateUrl: './wdx-nav-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxNavWrapperComponent {
    @HostBinding('class') class =
        'nav nav-tabs nav-flush flex-column text-center overflow-hidden flex-grow-1 flex-nowrap';
}
