import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconButtonModule } from '@wdx/shared/components/wdx-icon-button';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';

import { WdxAppSidebarComponent } from './wdx-app-sidebar.component';

@NgModule({
    imports: [
        CommonModule,
        WdxIconButtonModule,
        WdxIconModule,
        WdxIconContainerModule,
    ],
    declarations: [WdxAppSidebarComponent],
    exports: [WdxAppSidebarComponent],
})
export class WdxAppSidebarModule {}
