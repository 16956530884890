import { Injectable, inject } from '@angular/core';
import { FeatureDataService } from '../feature/feature-data.service';
import { MetaDataService } from '../meta/meta-data.service';
import { IMetaData } from '../meta/meta.service.interface';

import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { skip, takeUntil, tap } from 'rxjs/operators';
import { ActiveFeatureFacadeService } from './active-feature-facade.service';

@Injectable({
    providedIn: 'root',
})
export class ActiveFeatureService {
    private featureDataService = inject(FeatureDataService);
    private activeFeatureFacadeService = inject(ActiveFeatureFacadeService);
    private metaDataService = inject(MetaDataService);
    private activatedRoute = inject(ActivatedRoute);

    queryParamsChange(destroyed$: Observable<boolean>): void {
        this.activatedRoute.queryParams
            .pipe(
                skip(1),
                tap(() => {
                    this.resetFeatureConfig();
                }),
                takeUntil(destroyed$)
            )
            .subscribe({
                next: () => {
                    this.updateActiveFeature();
                },
            });
    }

    resetFeatureConfig(): void {
        this.featureDataService.isNewFeatureLoading$.next(true);
        this.featureDataService.reset();
    }

    updateActiveFeature(filter?: { search?: string }): void {
        if (filter?.search) {
            this.featureDataService.searchControl$.next(filter.search);
        }
        this.findActiveFeature(this.activeFeatureFacadeService.featureCode);
        this.featureDataService.getFeatureData();
    }

    private findActiveFeature(featureCode: string): void {
        const FEATURE = this.metaDataService.metadata$.value.find(
            (metaData) => metaData.code === featureCode
        ) as IMetaData;

        this.featureDataService.sorting$.next({
            sortField: FEATURE?.columns[0].code,
            sortDescending: false,
        });
        this.activeFeatureFacadeService.setActiveFeature(FEATURE);
    }
}
