import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'wdx-app-shell',
    templateUrl: './wdx-app-shell.component.html',
    styleUrls: ['./wdx-app-shell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxAppShellComponent {
    private offcanvasService = inject(NgbOffcanvas);

    open(content: any) {
        this.offcanvasService.open(content, { panelClass: 'offcanvas-sm' });
    }
}
