import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { SET_HEADERS } from '@wdx/kamino/utils';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';

const CONTROLLER = 'export';

@Injectable({
    providedIn: 'root',
})
export class ExportService {
    private httpClient = inject(HttpClient);
    private configService = inject(ConfigService);

    zip(): Observable<HttpResponse<Blob>> {
        const headers = SET_HEADERS('export', 'all');

        return this.httpClient.get<Blob>(
            `${this.configService.config.apiDomain}/${CONTROLLER}/zip`,
            {
                responseType: 'blob' as 'json',
                observe: 'response',
                headers,
            }
        );
    }
}
