import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IMetaData } from './meta.service.interface';

@Injectable({
    providedIn: 'root',
})
export class MetaDataService {
    metadata$ = new BehaviorSubject<IMetaData[]>([]);
}
