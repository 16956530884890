import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxAppHeaderComponent } from './wdx-app-header.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxAppHeaderComponent],
    exports: [WdxAppHeaderComponent],
})
export class WdxAppHeaderModule {}
