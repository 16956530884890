import { Route } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AUDIT_ROUTE, FORM_ROUTE, IMPORT } from '@wdx/kamino/utils/constants';
import { AppShellComponent } from './shared/components/app-shell/app-shell.component';
import { FeatureComponent } from './shared/components/feature/feature.component';
import { TenantComponent } from './shared/components/tenant/tenant.component';
import { ACTIVE_FEATURE_GUARD } from './shared/guards/active-feature/active-feature.guard';
import { initialisationGuard } from './shared/guards/initialisation/initialisation.guard';

export const appRoutes: Route[] = [
    {
        path: '',
        canActivate: [AuthGuard, initialisationGuard],
        component: AppShellComponent,
        children: [
            {
                path: ':tenant',
                component: TenantComponent,
                children: [
                    {
                        path: 'home',
                        loadComponent: () =>
                            import('@wdx/kamino/features/dashboard').then(
                                (mod) => mod.DashboardComponent
                            ),
                    },
                    {
                        path: IMPORT,
                        data: {
                            featureCode: 'import',
                        },
                        canActivate: [ACTIVE_FEATURE_GUARD],
                        component: FeatureComponent,
                        loadChildren: () =>
                            import('@wdx/kamino/features/import').then(
                                (mod) => mod.IMPORT_ROUTES
                            ),
                    },
                    {
                        path: ':featureCode',
                        component: FeatureComponent,
                        canActivate: [ACTIVE_FEATURE_GUARD],
                        children: [
                            {
                                path: '',
                                loadComponent: () =>
                                    import(
                                        './features/listing/components/list/list.component'
                                    ).then((mod) => mod.ListComponent),
                            },

                            {
                                path: AUDIT_ROUTE,
                                loadComponent: () =>
                                    import('@wdx/kamino/features/audit').then(
                                        (mod) =>
                                            mod.KaminoFeaturesAuditComponent
                                    ),
                            },
                            {
                                path: FORM_ROUTE,
                                loadComponent: () =>
                                    import('@wdx/kamino/features/form').then(
                                        (mod) => mod.KaminoFeaturesFormComponent
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'error',
                loadComponent: () =>
                    import('./features/error/error.component').then(
                        (mod) => mod.ErrorComponent
                    ),
            },
        ],
    },

    { path: '**', redirectTo: '/', pathMatch: 'full' },
];
