<wdx-icon-button
    data-cy="app-shell-toggle-button"
    class="d-sm-block d-lg-none app-shell-toggle bg-primary-dark"
    size="lg"
    (wdxOnClick)="open(content)"
>
    <wdx-icon-container class="text-primary-dark-contrast" size="lg">
        <wdx-icon icon="bars"></wdx-icon>
    </wdx-icon-container>
</wdx-icon-button>

<ng-content select="wdx-app-header"></ng-content>
<ng-content select="wdx-app-sidebar"></ng-content>
<ng-content select="wdx-app-content"></ng-content>

<ng-template #content let-offcanvas>
    <div class="navbar bg-primary-dark">
        <div class="container-fluid">
            <wdx-icon-button (click)="offcanvas.dismiss('Cross click')">
                <wdx-icon-container
                    class="text-primary-dark-contrast"
                    size="lg"
                >
                    <wdx-icon icon="xmark" size="sm"></wdx-icon>
                </wdx-icon-container>
            </wdx-icon-button>
        </div>
    </div>

    <div
        class="side-nav flex-column flex-shrink-0 h-100 overflow-hidden bg-body side-nav--mobile"
    >
        <ng-content select="wdx-nav-wrapper"></ng-content>
    </div>
</ng-template>
