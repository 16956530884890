import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconButtonModule } from '@wdx/shared/components/wdx-icon-button';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';

import { WdxAppShellComponent } from './wdx-app-shell.component';

@NgModule({
    imports: [
        CommonModule,
        WdxIconModule,
        WdxIconButtonModule,
        WdxIconContainerModule,
    ],
    declarations: [WdxAppShellComponent],
    exports: [WdxAppShellComponent],
})
export class WdxAppShellModule {}
