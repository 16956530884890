import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { Avatar, WdxAvatarModule } from '@wdx/shared/components/wdx-avatar';
import { WdxDropdownModule } from '@wdx/shared/components/wdx-dropdown';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';

@Component({
    selector: 'wdx-user-menu',
    standalone: true,
    imports: [
        CommonModule,
        WdxIconModule,
        NgbDropdownModule,
        WdxDropdownModule,
        WdxAvatarModule,
    ],
    templateUrl: './wdx-user-menu.component.html',
    styleUrls: ['./wdx-user-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxUserMenuComponent {
    @Input() avatar!: Avatar;

    @Output() wdxOnLogout = new EventEmitter();

    logout() {
        this.wdxOnLogout.emit();
    }
}
