import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { Observable } from 'rxjs/internal/Observable';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import {
    KaminoSettingService,
    MetaDataService,
    MetaService,
    SystemDataService,
    SystemService,
} from '@wdx/kamino/api-service';
import { HOME } from '@wdx/kamino/utils/constants';
import { WdxAppThemeService } from '@wdx/shared/utils';
import { combineLatest } from 'rxjs';

export const initialisationGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot
): Observable<boolean> => {
    const systemService = inject(SystemService);
    const systemDataService = inject(SystemDataService);
    const metaService = inject(MetaService);
    const metaDataService = inject(MetaDataService);
    const kaminoSettingService = inject(KaminoSettingService);
    const wdxAppThemeService = inject(WdxAppThemeService);
    const router = inject(Router);

    let tenantData: { tenant: string; redirect: boolean };

    return combineLatest({
        whoAmI: systemService.whoAmI(),
        tenants: systemService.getTenant(),
    }).pipe(
        map((res) => {
            if (res.tenants) {
                systemDataService.tenants$.next(res.tenants);

                tenantData = systemDataService.setTenantData(
                    route?.firstChild?.params?.['tenant']
                );
            }

            if (tenantData.redirect) {
                router.navigate([tenantData.tenant, HOME]);
            }

            return res;
        }),
        filter((res) => Boolean(res.tenants) && Boolean(res.whoAmI)),
        switchMap((res) =>
            combineLatest({
                meta: metaService.getMeta(),
                theme: kaminoSettingService.getTheme(),
            }).pipe(
                map((tenantInfo) => {
                    return {
                        ...res,
                        meta: tenantInfo.meta,
                        theme: tenantInfo.theme,
                    };
                })
            )
        ),
        tap((res) => {
            systemDataService.whoAmI$.next(res.whoAmI);
            systemDataService.isLoading$.next(false);
            metaDataService.metadata$.next(res.meta);
            wdxAppThemeService.set(res.theme.value);
        }),
        map(() => true)
    );
};
