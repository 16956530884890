<div class="d-flex align-items-center divider-end">
    <ng-container *ngIf="selectedTenant$ | ngrxPush as selectedTenant">
        <wdx-dropdown ngbDropdown display="dynamic" #dropdown="ngbDropdown">
            <button
                ngbDropdownToggle
                class="btn fw-normal d-flex align-items-center text-primary-contrast"
            >
                <span class="ms-1 fw-bold" data-cy="kmo-ui-tenant-selected">{{
                    selectedTenant?.name
                }}</span>
                <wdx-icon
                    class="ms-2 toggle-icon"
                    icon="chevron-down"
                    [rotate]="dropdown.isOpen() ? 180 : 0"
                ></wdx-icon>
            </button>

            <wdx-dropdown-menu ngbDropdownMenu>
                <button
                    class="d-flex"
                    [attr.data-cy]="'kmo-ui-tenant-item-' + i"
                    *ngFor="let tenant of tenants; let i = index"
                    ngbDropdownItem
                    (click)="switchTenant(tenant)"
                >
                    {{ tenant?.name }}

                    <wdx-icon
                        *ngIf="selectedTenant?.name === tenant?.name"
                        class="ms-auto"
                        icon="check"
                    ></wdx-icon>
                </button>
            </wdx-dropdown-menu>
        </wdx-dropdown>
    </ng-container>
</div>
