import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[wdxNavLink]',
})
export class WdxNavLinkDirective {
    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        this.renderer.addClass(this.elementRef.nativeElement, 'nav-link');
        this.renderer.addClass(this.elementRef.nativeElement, 'd-flex');
        this.renderer.addClass(
            this.elementRef.nativeElement,
            'align-items-center'
        );
        this.renderer.addClass(
            this.elementRef.nativeElement,
            'justify-content-start'
        );
    }
}
