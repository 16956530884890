import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { PAGE_DEFAULT, SORTING_DEFAULT } from '@wdx/kamino/utils/constants';
import { PaginatedApiResponse, Paging } from '@wdx/shared/utils';
import {
    Observable,
    combineLatest,
    debounceTime,
    skip,
    skipWhile,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ActiveFeatureFacadeService } from '../active-feature/active-feature-facade.service';
import { ActiveFeatureRecordsFacade } from '../active-feature/active-feature-records-facade.service';
import { EnumFormType, EnumHttpMethod } from '../meta/meta.service.interface';
import {
    DynamicItem,
    EnumDynamicItemEventType,
    IFormPostData,
} from './feature-data.interface';
import { FeatureService } from './feature.service';

@Injectable({
    providedIn: 'root',
})
export class FeatureDataService {
    paging$ = new BehaviorSubject<Paging>(PAGE_DEFAULT);
    isNewFeatureLoading$ = new BehaviorSubject(true);
    isLoading$ = new BehaviorSubject(true);
    hasError$ = new BehaviorSubject(false);
    searchControl$ = new BehaviorSubject<string | null>('');
    disabledControl$ = new BehaviorSubject(false);
    isLocal$ = new BehaviorSubject(false);
    sorting$ = new BehaviorSubject(SORTING_DEFAULT);
    isSkipping$ = new BehaviorSubject(true);

    private activeFeatureFacadeService = inject(ActiveFeatureFacadeService);
    private featureService = inject(FeatureService);
    private activeFeatureRecordsFacade = inject(ActiveFeatureRecordsFacade);

    filterQueries(destroyed$: Observable<boolean>): void {
        combineLatest([
            this.searchControl$.pipe(
                tap(() => {
                    this.paging$.next({
                        ...this.paging$.value,
                        page: 1,
                    });
                })
            ),
            this.disabledControl$,
            this.isLocal$,
        ])
            .pipe(
                takeUntil(destroyed$),
                skip(1),
                skipWhile(() => this.isSkipping$.value),
                debounceTime(250),
                switchMap(() => this.getFeatureData$())
            )
            .subscribe(this.gettingFeatureSubscribeObject());
    }

    updateQueryParamsPaging(event: any): void {
        const PAGE = event?.page ? event?.page : 1;
        this.sorting$.next({
            sortField: event.sortField,
            sortDescending: event.sortOrder !== 1,
        });

        this.paging$.next({
            ...this.paging$.value,
            page: PAGE,
            pageSize: 20,
        });

        this.getFeatureData();
    }

    getFeatureData(): void {
        this.getFeatureData$().subscribe(this.gettingFeatureSubscribeObject());
    }

    gettingFeatureSubscribeObject() {
        return {
            next: (featureData: PaginatedApiResponse<DynamicItem>) => {
                this.isLoading$.next(false);
                this.isNewFeatureLoading$.next(false);
                this.activeFeatureRecordsFacade.setRecords(featureData.results);
                this.paging$.next(featureData.paging);
            },
            error: () => {
                this.hasError$.next(true);
            },
        };
    }

    getFeatureData$(): Observable<PaginatedApiResponse<DynamicItem>> {
        this.isLoading$.next(true);

        const BODY = {
            ...(this.searchControl$.value
                ? { search: this.searchControl$.value }
                : {}),
            ...(this.disabledControl$.value
                ? { isDisabled: this.disabledControl$.value }
                : {}),
            ...(this.isLocal$.value ? { isLocal: this.isLocal$.value } : {}),
            ...(this.sorting$.value.sortField ? this.sorting$.value : {}),
        };

        return this.featureService.getFeatureData(
            this.activeFeatureFacadeService.featureCode,
            this.paging$.value,
            BODY
        );
    }

    doDynamicAction(method: EnumHttpMethod, url: string): void {
        this.featureService
            .doDynamicAction(
                method,
                this.activeFeatureFacadeService.featureCode,
                url
            )
            .subscribe({
                next: () => {
                    this.getFeatureData();
                },
            });
    }

    putFeatureItem(code: string, body: IFormPostData): Observable<DynamicItem> {
        const RECORD_TYPE = this.activeFeatureRecordsFacade.record
            ? EnumDynamicItemEventType.Updated
            : EnumDynamicItemEventType.Created;

        return this.featureService.putFeatureItem(
            this.activeFeatureFacadeService.featureCode,
            code,
            this.activeFeatureFacadeService.activeFeature?.type as EnumFormType,
            body,
            RECORD_TYPE
        );
    }

    toggleDisabled(url: string): void {
        this.disabledEvent(url).subscribe({
            next: () => {
                this.getFeatureData();
            },
        });
    }

    reset(): void {
        this.isSkipping$.next(true);
        this.disabledControl$.next(false);
        this.isLocal$.next(false);
        this.searchControl$.next('');
        this.sorting$.next(SORTING_DEFAULT);
        this.paging$.next(PAGE_DEFAULT);
        this.isSkipping$.next(false);
    }

    private disabledEvent(url: string): Observable<any> {
        const record = this.activeFeatureRecordsFacade.record as DynamicItem;
        if (record.isDisabled) {
            return this.featureService.putFeatureItem(
                this.activeFeatureFacadeService.featureCode,
                url,
                EnumFormType.Form,
                { ...record, isDisabled: false },
                EnumDynamicItemEventType.Updated
            );
        }

        return this.featureService.deleteFeatureItem(
            this.activeFeatureFacadeService.featureCode,
            url
        );
    }

    /**
     *
     * Constructs a path by joining the featureCode and the extracted identifiers with '/' and then fetches the data at that path.
     *
     * @returns {Observable<any>} - that emits the data fetched from the constructed path
     */

    getFormData$(record: Params): Observable<any> {
        return this.featureService.getFormData(
            [
                this.activeFeatureFacadeService.featureCode,
                ...this.extractRecordIdValues(record),
            ].join('/')
        );
    }

    /**
     *
     * Extracts unique identifier strings from the activeFeatureRecordsFacade record.
     * Determines unique identifiers, defaulting to an empty array if none are provided in 'activeFeatureFacadeService.activeFeature'.
     * Maps each unique identifier to its corresponding string value in the record and returns these values as an array of strings.
     *
     * @returns {Array<string>} - representing the values corresponding to the unique identifiers in the 'record'
     */
    extractRecordIdValues(record: Params): Array<string> {
        const uniqueIdentifiers =
            this.activeFeatureFacadeService.activeFeature?.uniqueIdentifiers ??
            [];
        return uniqueIdentifiers.map((uniqueIdentifier) => {
            const value = record[uniqueIdentifier as keyof DynamicItem];
            return value !== undefined ? String(value) : '';
        });
    }
    /**
     * Generates a query parameters object based on the provided selectedItem.
     * The keys for the queryParams are defined by the uniqueIdentifiers.
     *
     * @param selectedItem - contains properties that match the uniqueIdentifiers.
     * @returns - An object where each key is a unique identifier and each value is the string representation
     * of the corresponding property from the selectedItem.
     *
     */
    generateQueryParams(selectedItem: Params): { [key: string]: string } {
        if (!selectedItem) {
            return {};
        }

        const queryParams: { [key: string]: string } = {};
        const uniqueIdentifiers =
            this.activeFeatureFacadeService.activeFeature?.uniqueIdentifiers ??
            [];

        uniqueIdentifiers.forEach((key) => {
            const value = selectedItem[key];
            if (value) {
                queryParams[key] = value.toString();
            }
        });

        return queryParams;
    }

    joinUniqueIdentifiers(data: { [key: string]: any }): string {
        if (!data || Object.keys(data).length === 0) {
            return '';
        }
        return this.extractRecordIdValues(data).join('/');
    }
}
