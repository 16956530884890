import { InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken<IConfig>('app.config');

export interface IConfig {
    authDomain: string;
    clientId: string;
    audience: string;
    apiDomain: string;
}
