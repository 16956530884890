import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { ISettingTheme } from './setting.interface';

const SETTING_CONTROLLER = 'setting';

@Injectable({
    providedIn: 'root',
})
export class KaminoSettingService {
    private httpClient = inject(HttpClient);
    private configService = inject(ConfigService);

    getTheme(): Observable<ISettingTheme> {
        return this.httpClient.get<ISettingTheme>(
            `${this.configService.config.apiDomain}/${SETTING_CONTROLLER}/theme`
        );
    }
}
