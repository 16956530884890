import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DynamicItem } from '../feature/feature-data.interface';
import { IMetaData } from '../meta/meta.service.interface';

@Injectable({
    providedIn: 'root',
})
export class ActiveFeatureFacadeService {
    private _activeFeature$ = new BehaviorSubject<IMetaData | null>(null);
    get activeFeature$() {
        return this._activeFeature$.asObservable();
    }
    get activeFeature() {
        return this._activeFeature$.value;
    }

    private _featureCode!: string;
    public get featureCode(): string {
        return this._featureCode;
    }

    /**
     *
     * @param {DynamicItem} record - This is the recorded need edited or viewed
     * @param {any} alternativeData - This is the extra data that would be needed if a new record is been created
     * @returns {string} - This will be a string that should be use as the path for the record
     */
    getRecordPath(record: DynamicItem, alternativeData?: any): string {
        const URL: string[] = [];

        this.activeFeature?.uniqueIdentifiers.forEach((name: string) => {
            const VAL =
                alternativeData && name in alternativeData
                    ? alternativeData[name]
                    : record?.[name as keyof DynamicItem];
            URL.push(VAL);
        });

        return URL.join('/');
    }

    /**
     *
     * @param {IMetaData} activeFeature - This is used to set the active feature
     */
    setActiveFeature(activeFeature: IMetaData): void {
        this._activeFeature$.next(activeFeature);
    }

    /**
     * @param {string} code - This is used to set the active feature code
     */
    setFeatureCode(code: string): void {
        if (code) {
            this._featureCode = code;
        }
    }
}
