import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG, IConfig } from './config.interface';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    config: IConfig;

    constructor(@Inject(APP_CONFIG) config: IConfig) {
        this.config = config;
    }
}
