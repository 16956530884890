import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
    selector: 'wdx-app-sidebar',
    templateUrl: './wdx-app-sidebar.component.html',
    styleUrls: ['./wdx-app-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxAppSidebarComponent {
    @Input() collapsed = false;

    @Output() wdxOnCollapsed = new EventEmitter(this.collapsed);

    toggle(): void {
        this.collapsed = !this.collapsed;
        this.wdxOnCollapsed.emit(this.collapsed);
    }
}
