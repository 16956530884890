import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { ITenant, IWhoAmI } from './system.service.interface';

const CONTROL = 'system';

@Injectable({
    providedIn: 'root',
})
export class SystemService {
    private httpClient = inject(HttpClient);
    private configService = inject(ConfigService);

    whoAmI(): Observable<IWhoAmI> {
        return this.httpClient.get<IWhoAmI>(
            `${this.configService.config.apiDomain}/${CONTROL}/whoami`
        );
    }

    getTenant(): Observable<ITenant[]> {
        return this.httpClient.get<ITenant[]>(
            `${this.configService.config.apiDomain}/${CONTROL}/tenants`
        );
    }
}
