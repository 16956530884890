import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SET_HEADERS } from '@wdx/kamino/utils';
import { PaginatedApiResponse, Paging } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { EnumFormType, EnumHttpMethod } from '../meta/meta.service.interface';
import {
    DynamicItem,
    EnumDynamicItemEventType,
    IAudit,
    IFormPostData,
} from './feature-data.interface';

@Injectable({
    providedIn: 'root',
})
export class FeatureService {
    private httpClient = inject(HttpClient);
    private configService = inject(ConfigService);

    getFeatureData(
        feature: string,
        paging: Paging,
        body: any = {}
    ): Observable<PaginatedApiResponse<DynamicItem>> {
        const PAYLOAD = {
            ...(body.search && { search: body.search }),
            ...(body.isDisabled && { isDisabled: body.isDisabled }),
            ...(body.isLocal && { isLocal: body.isLocal }),
        };

        const headers: HttpHeaders = SET_HEADERS(feature, null, paging, {
            sortField: body.sortField,
            sortDescending: body.sortDescending,
        });

        return this.httpClient.post<PaginatedApiResponse<any>>(
            `${this.configService.config.apiDomain}/${feature}/search`,
            PAYLOAD,
            { headers }
        );
    }

    putFeatureItem(
        feature: string,
        code: string,
        formType: EnumFormType,
        body: IFormPostData,
        type: EnumDynamicItemEventType
    ): Observable<DynamicItem> {
        const headers = SET_HEADERS(feature, code, null, null, type);

        let cloneBody = { ...body };

        /**
         * Sending the code in Simple forms creates an issue.
         * The endpoints is the only place the `code` is needed.
         */
        if ('code' in cloneBody) {
            delete cloneBody['code'];
        }

        /**
         * We only need the value for Simple forms
         */
        if (formType === EnumFormType.Simple) {
            const keys = Object.keys(cloneBody);
            cloneBody = cloneBody[keys[0]];
        }

        return this.httpClient.put<DynamicItem>(
            `${this.configService.config.apiDomain}/${feature}/${code}`,
            cloneBody,
            { headers }
        );
    }

    deleteFeatureItem(feature: string, url: string): Observable<any> {
        const headers = SET_HEADERS(feature, url);

        return this.httpClient.delete<any>(
            `${this.configService.config.apiDomain}/${feature}/${url}`,
            { headers }
        );
    }

    doDynamicAction(
        method: EnumHttpMethod,
        feature: string,
        url: string
    ): Observable<any> {
        const headers: HttpHeaders = SET_HEADERS(feature, url);
        const apiUrl = `${this.configService.config.apiDomain}/${url}`;

        switch (method) {
            case EnumHttpMethod.Delete:
            case EnumHttpMethod.Get:
                return this.httpClient[method](apiUrl, { headers });
            case EnumHttpMethod.Put:
            case EnumHttpMethod.Post:
                return this.httpClient[method]<any>(apiUrl, {}, { headers });
            default:
                return this.httpClient[method](apiUrl, { headers });
        }
    }

    getAudit(
        feature: string,
        url: string,
        paging: Paging,
        body: any = {}
    ): Observable<PaginatedApiResponse<IAudit>> {
        const PAYLOAD = {
            ...(body?.search && { search: body.search }),
        };

        const headers = SET_HEADERS(feature, url, paging);

        return this.httpClient.post<PaginatedApiResponse<IAudit>>(
            `${this.configService.config.apiDomain}/${feature}/${url}/audit/search`,
            PAYLOAD,
            { headers }
        );
    }

    /**
     *
     * Combines the configService API base URL with the given URL extension to make the HTTP GET request.
     *
     * @param {string} path
     * @returns {Observable<any>}
     */
    getFormData(path: string): Observable<any> {
        return this.httpClient.get<any>(
            `${this.configService.config.apiDomain}/${path}`
        );
    }
}
