<div class="modal-header d-flex align-items-center">
    <h5 class="modal-title text-truncate">
        <ng-content select="[title]"></ng-content>
    </h5>
    <button
        type="button"
        class="close"
        aria-label="Close"
        data-cy="btn-close"
        (click)="modal.close()"
    >
        <wdx-icon icon="times"></wdx-icon>
    </button>
</div>
<div class="modal-body">
    <ng-content select="[body]"></ng-content>
</div>
