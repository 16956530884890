import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxNavItemComponent } from './item';
import { WdxNavLinkDirective } from './link/wdx-nav-link.directive';
import { WdxNavWrapperComponent } from './wrapper';

@NgModule({
    imports: [CommonModule],
    declarations: [
        WdxNavWrapperComponent,
        WdxNavItemComponent,
        WdxNavLinkDirective,
    ],
    exports: [WdxNavWrapperComponent, WdxNavItemComponent, WdxNavLinkDirective],
})
export class WdxNavModule {}
