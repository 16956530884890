import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';

import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { APP_CONFIG, TenantInterceptor } from '@wdx/kamino/api-service';

import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { ToastrInterceptor } from './app/shared/interceptors/toastr/toastr.interceptor';

fetch('/assets/config.json')
    .then((res) => res.json())
    .then((config) => {
        bootstrapApplication(AppComponent, {
            providers: [
                provideRouter(appRoutes),
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthHttpInterceptor,
                    multi: true,
                },
                { provide: APP_CONFIG, useValue: config },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: TenantInterceptor,
                    multi: true,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: ToastrInterceptor,
                    multi: true,
                },
                importProvidersFrom(
                    HttpClientModule,
                    AuthModule.forRoot({
                        domain: config.authDomain,
                        clientId: config.clientId,
                        audience: config.audience,
                        scope: 'access:user',
                        authorizationParams: {
                            redirect_uri: window.location.origin,
                        },
                        useRefreshTokens: true,
                        errorPath: '/error',
                        httpInterceptor: {
                            allowedList: [
                                {
                                    uri: `${config.apiDomain}/*`,
                                    tokenOptions: {
                                        authorizationParams: {
                                            audience: config.audience,
                                            scope: 'access:user',
                                        },
                                    },
                                },
                            ],
                        },
                    })
                ),
            ],
        }).catch((err) => console.error(err));
    });
