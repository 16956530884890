import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';

@Component({
    selector: 'kmo-modal-wrapper',
    standalone: true,
    imports: [WdxIconModule],
    templateUrl: './modal-wrapper.component.html',
    styleUrls: ['./modal-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KaminoModalWrapperComponent {
    public modal = inject(NgbActiveModal);
}
