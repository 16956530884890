import { Option } from '../meta/meta.service.interface';

export enum EnumDynamicItemEventType {
    Updated = 'updated',
    Created = 'created',
}

export enum EnumConfigLevel {
    Base = 'Base',
    Local = 'Local',
    Client = 'Client',
}

export interface DynamicItem {
    category?: string;
    code: string;
    configLevel: EnumConfigLevel;
    description?: string;
    hash: string;
    isDisabled: boolean;
    name: string;
    options?: Option[];
    tenant: string;
    type?: string;
    value: string;
    id?: string;
    formVersion?: number;
    schema?: Record<string | number, any>[];
}

export interface IAudit {
    timestamp: string;
    loginName: string;
    targetType: string;
    targetId: number;
    action: string;
    pre: any;
    post: any;
}

export interface IFormPostData {
    [key: string | number]: any;
}
