import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { DynamicItem } from '../feature/feature-data.interface';

@Injectable({
    providedIn: 'root',
})
export class ActiveFeatureRecordsFacade {
    private _records$ = new BehaviorSubject<DynamicItem[]>([]);
    private _record$ = new BehaviorSubject<DynamicItem | undefined>(undefined);

    get records(): DynamicItem[] {
        return this._records$.value;
    }

    get records$(): Observable<DynamicItem[]> {
        return this._records$.asObservable();
    }

    get record$(): Observable<DynamicItem | undefined> {
        return this._record$.asObservable();
    }

    get record(): DynamicItem | undefined {
        return this._record$.value;
    }

    setRecord(record: DynamicItem | undefined): void {
        this._record$.next(record);
    }

    setRecords(records: DynamicItem[]): void {
        this._records$.next(records);
    }
}
