<ngx-dropzone
    #drop
    class="mb-5"
    [accept]="
        'application/x-zip-compressed,application/zip,application/zip-compressed'
    "
    [multiple]="false"
    [disableClick]="false"
    [disabled]="$any(fileUploaderService.disabled$ | ngrxPush)"
    (change)="onAddFiles($event)"
>
    <ngx-dropzone-label>
        <ng-container>
            <div class="text-center body-text-medium">
                <div>Drag and drop your file here</div>
                <div>or</div>
            </div>
            <div class="d-flex justify-content-center">
                <button class="mx-1" [wdxButton]="WDX_BUTTON_STYLE.Link">
                    Browse
                </button>
            </div>
        </ng-container>
    </ngx-dropzone-label>
</ngx-dropzone>

<ng-container *ngFor="let file of acceptedFiles; let i = index">
    <ngb-progressbar
        height=".5rem"
        [type]="
            (fileUploaderService.uploadedFile$ | ngrxPush)
                ? 'success'
                : (fileUploaderService.errorUploadingFile$ | ngrxPush)
                ? 'error'
                : 'info'
        "
        [value]="$any(fileUploaderService.uploadProgress$) | ngrxPush"
    ></ngb-progressbar>

    <wdx-info-card
        *ngFor="let file of acceptedFiles; let i = index"
        [class.card-danger]="fileUploaderService.errorUploadingFile$ | ngrxPush"
        [wdxIsLoading]="(fileUploaderService.fileEvent$ | ngrxPush)!"
        [loadingOverlay]="true"
    >
        <div wdxInfoCardLeading class="separator">
            <wdx-icon-container class="bg-icon" size="lg">
                <wdx-icon icon="file-zip"></wdx-icon>
            </wdx-icon-container>
        </div>

        <div wdxInfoCardLabel>
            {{ file.name }}
        </div>

        <div wdxInfoCardSub>
            {{ file.type }} <b>{{ file.size | fileSize }}</b>
            <ul
                class="mt-2"
                *ngIf="
                    fileUploaderService.uploadedFile$ | ngrxPush as uploadedFile
                "
            >
                <li>
                    Records found: <b>{{ uploadedFile.recordsFound }}</b>
                </li>
                <li>
                    Source environment:
                    <b>{{ uploadedFile.sourceEnvironment }}</b>
                </li>
                <li>
                    Source Source tenant: <b>{{ uploadedFile.sourceTenant }}</b>
                </li>
                <li>
                    Target tenant: <b>{{ uploadedFile.targetTenant }}</b>
                </li>
            </ul>
        </div>

        <div wdxInfoCardTrailing>
            <ng-container
                *ngIf="
                    fileUploaderService.uploadedFile$ | ngrxPush as uploadedFile
                "
            >
                <button
                    class="me-1"
                    title="execute"
                    size="sm"
                    [wdxIconButton]="true"
                    (click)="onFileEvent('execute')"
                >
                    <wdx-icon-container size="sm">
                        <wdx-icon [icon]="ICONS.check"></wdx-icon>
                    </wdx-icon-container>
                </button>

                <button
                    title="abort"
                    size="sm"
                    [wdxIconButton]="true"
                    (click)="onFileEvent('abort')"
                >
                    <wdx-icon-container size="sm">
                        <wdx-icon [icon]="ICONS.close"></wdx-icon>
                    </wdx-icon-container>
                </button>
            </ng-container>
        </div>
    </wdx-info-card>
</ng-container>
