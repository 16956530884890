import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'wdx-app-content',
    templateUrl: './wdx-app-content.component.html',
    styleUrls: ['./wdx-app-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxAppContentComponent {
    @Input() collapsed = false;

    toggle(): void {
        this.collapsed = !this.collapsed;
    }
}
