import { HttpHeaders } from '@angular/common/http';
import { Paging } from '@wdx/shared/utils';

export const HEADER_PAGE_NUMBER = 'X-Clmi-Pagenumber';
export const HEADER_PAGE_SIZE = 'X-Clmi-Pagesize';
export const HEADER_SORT_FIELD = 'X-Clmi-Sortfield';
export const HEADER_SORT_DESCENDING = 'X-Clmi-Sortdescending';
export const HEADER_FEATURE = 'X-Clmi-Feature';
export const HEADER_CODE = 'X-Clmi-Code';
export const HEADER_EVENT_TYPE = 'X-Clmi-EventType';

export const SET_HEADERS = (
    feature: string,
    code: string | null,
    paging?: Paging | null,
    sorting?: { sortField: string; sortDescending: boolean } | null,
    eventType?: string
): HttpHeaders => {
    let headers: HttpHeaders = new HttpHeaders();

    if (paging) {
        headers = headers
            .append(HEADER_PAGE_NUMBER, String(paging.page))
            .append(HEADER_PAGE_SIZE, String(paging.pageSize));
    }

    if (sorting?.sortField) {
        headers = headers
            .append(HEADER_SORT_FIELD, sorting.sortField)
            .append(HEADER_SORT_DESCENDING, String(sorting.sortDescending));
    }

    if (feature) {
        headers = headers.append(HEADER_FEATURE, feature);
    }

    if (code) {
        headers = headers.append(HEADER_CODE, code);
    }

    if (eventType) {
        headers = headers.append(HEADER_EVENT_TYPE, eventType);
    }

    return headers;
};
