import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
    standalone: true,
    imports: [RouterOutlet],
    templateUrl: './tenant.component.html',
    styleUrls: ['./tenant.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TenantComponent {}
