import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    inject,
} from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PushModule } from '@ngrx/component';
import { ITenant, SystemDataService } from '@wdx/kamino/api-service';
import { WdxDropdownModule } from '@wdx/shared/components/wdx-dropdown';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';

@Component({
    selector: 'kmo-tenant',
    standalone: true,
    imports: [
        CommonModule,
        WdxDropdownModule,
        WdxIconModule,
        NgbDropdownModule,
        PushModule,
    ],
    templateUrl: './tenant.component.html',
    styleUrls: ['./tenant.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KaminoTenantComponent {
    @Input() tenants: ITenant[] | undefined = [];

    @Output() tenantSwitch = new EventEmitter<ITenant>();

    private systemDataService = inject(SystemDataService);

    selectedTenant$ = this.systemDataService.tenant$;

    switchTenant(tenant: ITenant): void {
        this.systemDataService.setTenantData(tenant?.code);
        this.tenantSwitch.emit(tenant);
    }
}
