import {
    UTILS_ICON_ADD,
    UTILS_ICON_ANNOUNCEMENT,
    UTILS_ICON_ARROW_DOWNLOAD,
    UTILS_ICON_ARROW_UPLOAD,
    UTILS_ICON_AUDIT,
    UTILS_ICON_CHECK,
    UTILS_ICON_CLOSE,
    UTILS_ICON_DELETE,
    UTILS_ICON_DELETE_UNDO,
    UTILS_ICON_DOCUMENT,
    UTILS_ICON_EYE,
    UTILS_ICON_INFO,
    UTILS_ICON_PENCIL,
    UTILS_ICON_PROMOTE,
    UTILS_ICON_RESET,
} from '@wdx/shared/utils';

export const ICONS = {
    edit: UTILS_ICON_PENCIL,
    close: UTILS_ICON_CLOSE,
    check: UTILS_ICON_CHECK,
    add: UTILS_ICON_ADD,
    audit: UTILS_ICON_AUDIT,
    trash: UTILS_ICON_DELETE,
    trashUndo: UTILS_ICON_DELETE_UNDO,
    upload: UTILS_ICON_ARROW_UPLOAD,
    download: UTILS_ICON_ARROW_DOWNLOAD,
    view: UTILS_ICON_EYE,
    announcement: UTILS_ICON_ANNOUNCEMENT,
    file: UTILS_ICON_DOCUMENT,
    info: UTILS_ICON_INFO,
    promote: UTILS_ICON_PROMOTE,
    reset: UTILS_ICON_RESET,
};
