import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
@Component({
    standalone: true,
    imports: [RouterOutlet],
    templateUrl: './feature.component.html',
    styleUrls: ['./feature.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureComponent {}
