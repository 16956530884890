import { HttpEventType, HttpResponse } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    inject,
} from '@angular/core';
import { PushModule } from '@ngrx/component';
import {
    IImport,
    ImportEventType,
    ImportFacade,
} from '@wdx/kamino/api-service';
import { KaminoModalWrapperComponent } from '@wdx/kamino/ui';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { KaminoFileUploaderComponent } from '../shared/components/file-uploader/file-uploader.component';
import { KaminoFileUploaderService } from '../shared/components/file-uploader/file-uploader.service';

@Component({
    selector: 'kamino-file-upload',
    standalone: true,
    imports: [
        KaminoModalWrapperComponent,
        KaminoFileUploaderComponent,
        PushModule,
    ],
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KaminoFeaturesFileUploadComponent
    extends WdxDestroyClass
    implements OnInit
{
    private fileUploaderService = inject(KaminoFileUploaderService);
    private importFacade = inject(ImportFacade);

    ngOnInit(): void {
        this.fileUploaderService.files$
            .pipe(
                takeUntil(this.destroyed$),
                filter((files) => Boolean(files.length)),
                tap(() => {
                    this.fileUploaderService.setDisabled(true);
                }),
                map((files) => files[0]),
                switchMap((file) => this.importFacade.sendData$(file))
            )
            .subscribe({
                next: (res) => {
                    if (res.type === HttpEventType.UploadProgress) {
                        this.fileUploaderService.setUploadProgress(
                            (100 * res.loaded) / (res?.total as number)
                        );
                    }

                    if (res instanceof HttpResponse) {
                        this.fileUploaderService.setDisabled(false);
                        this.fileUploaderService.setUploadedFile(
                            res.body as IImport
                        );
                    }
                },
                error: (error) => {
                    this.fileUploaderService.addError(error);
                    this.fileUploaderService.setDisabled(false);
                },
            });
    }

    onFileEvent(fileEvent: ImportEventType): void {
        const ID = (this.fileUploaderService.uploadedFile as IImport)?.id;
        this.fileUploaderService.setFiledEvent(true);
        this.importFacade
            .importEvent$(fileEvent, ID)
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: () => {
                    this.fileUploaderService.setFiledEvent(false);
                    this.fileUploaderService.dismiss();
                },
                error: () => {
                    this.fileUploaderService.setFiledEvent(false);
                },
            });
    }
}
