import { HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { FILE } from '@wdx/kamino/utils/constants';
import { KaminoRecentRecordsService } from '@wdx/kamino/utils/services';
import { DOWNLOAD } from '@wdx/shared/utils';
import { Observable, map } from 'rxjs';
import { IImport, ImportEventType } from './import.interface';
import { ImportService } from './import.service';

@Injectable({
    providedIn: 'root',
})
export class ImportFacade {
    private importService = inject(ImportService);
    private recentRecordsService = inject(KaminoRecentRecordsService);

    /**
     *
     * @param {File} file - This is the zip file that needs to be uploaded
     *
     * @returns {Observable<IImport>}
     */
    getImportById$(id: string): Observable<IImport> {
        return this.importService.getImportById(id);
    }

    /**
     *
     * @param {File} file - This is the zip file that needs to be uploaded
     *
     * @returns {Observable<HttpEvent<IImport>>}
     */
    sendData$(file: File): Observable<HttpEvent<IImport>> {
        return this.importService.sendData(file).pipe(
            map((res) => {
                if (res instanceof HttpResponse) {
                    this.setRecentEvent((res.body as IImport).id, 'upload');
                }

                return res;
            })
        );
    }

    /**
     *
     * @param {ImportEventType} event
     * @param {string} id
     * @returns {Observable<unknown>}
     */
    importEvent$(event: ImportEventType, id: string): Observable<unknown> {
        const IMPORT_EVENT =
            event === 'execute'
                ? this.importService.execute(id)
                : this.importService.abort(id);

        return IMPORT_EVENT.pipe(
            map((res) => {
                this.setRecentEvent(id, event);
                return res;
            })
        );
    }

    /**
     *
     * @param {IImport} importInfo - This is the info of the file import
     *
     * @returns {Observable<HttpResponse<Blob>>}
     */
    getFileDownload$(importInfo: IImport): Observable<HttpResponse<Blob>> {
        return this.importService.getFile(importInfo).pipe(
            map((res) => {
                if (res.status === 200) {
                    this.setRecentEvent(importInfo.id, 'download');
                    DOWNLOAD(
                        `${importInfo.sourceEnvironment}-${importInfo.targetTenant}-${importInfo.created}`,
                        res
                    );
                }

                return res;
            })
        );
    }

    private setRecentEvent(code: string, type: string): void {
        this.recentRecordsService.setRecentRecord(FILE, {
            feature: 'import',
            code,
            type,
        });
    }
}
