import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { IMetaData } from './meta.service.interface';

@Injectable({
    providedIn: 'root',
})
export class MetaService {
    private httpClient = inject(HttpClient);
    private configService = inject(ConfigService);

    getMeta(): Observable<IMetaData[]> {
        return this.httpClient.get<IMetaData[]>(
            `${this.configService.config.apiDomain}/metadata`
        );
    }
}
