import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import {
    ActiveFeatureFacadeService,
    ActiveFeatureService,
} from '@wdx/kamino/api-service';
import { FEATURE_CODE } from '@wdx/kamino/utils/constants';
import { Observable, of } from 'rxjs';

export const ACTIVE_FEATURE_GUARD: CanActivateFn = (
    route: ActivatedRouteSnapshot
): Observable<boolean> => {
    const activeFeatureFacadeService = inject(ActiveFeatureFacadeService);
    const activeFeatureService = inject(ActiveFeatureService);

    const ACTIVE_FEATURE_CODE =
        route.params[FEATURE_CODE] || route.data[FEATURE_CODE];
    const SEARCH = route.queryParams['search'];

    if (!ACTIVE_FEATURE_CODE) {
        console.error('Needs featureCode');
    }

    activeFeatureFacadeService.setFeatureCode(ACTIVE_FEATURE_CODE);
    activeFeatureService.resetFeatureConfig();
    activeFeatureService.updateActiveFeature({ search: SEARCH });

    return of(true);
};
