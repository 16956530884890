<wdx-app-shell>
    <wdx-app-header>
        <img src="assets/img/CLMi-Kamino-Logo.svg" width="180" alt="Kamino" />
        <h5 class="ms-2 text-capitalize">{{ environment }}</h5>

        <button
            *ngIf="{ value: downloading$ | ngrxPush } as downloading"
            title="download"
            class="me-1"
            appBarOptions
            [wdxButton]="WDX_BUTTON_STYLE.Primary"
            [wdxIconButton]="true"
            [wdxButtonSize]="WDX_BUTTON_SIZE.Lg"
            (click)="exportZip(downloading?.value)"
        >
            <wdx-icon-container size="lg">
                <wdx-spinner
                    *ngIf="downloading?.value"
                    class="align-self-baseline"
                    [small]="true"
                ></wdx-spinner>

                <wdx-icon
                    *ngIf="!downloading?.value"
                    [icon]="ICONS.download"
                    [fixedWidth]="true"
                ></wdx-icon>
            </wdx-icon-container>
        </button>

        <button
            title="upload"
            appBarOptions
            [wdxButton]="WDX_BUTTON_STYLE.Primary"
            [wdxIconButton]="true"
            [wdxButtonSize]="WDX_BUTTON_SIZE.Lg"
            (click)="openImport()"
        >
            <wdx-icon-container size="lg">
                <wdx-icon [icon]="ICONS.upload" [fixedWidth]="true"></wdx-icon>
            </wdx-icon-container>
        </button>

        <kmo-tenant
            appBarOptions
            [tenants]="tenants$ | ngrxPush"
            (tenantSwitch)="onTenantSwitch($event)"
        ></kmo-tenant>

        <wdx-user-menu
            appBarOptions
            [avatar]="avatar"
            (wdxOnLogout)="wdxOnLogout()"
        >
            <button class="dropdown-item" (click)="switchTheme()">
                <wdx-icon [icon]="themeInfo.icon" class="me-2"></wdx-icon>
                Switch to {{ themeInfo.name }} theme
            </button>
        </wdx-user-menu>
    </wdx-app-header>

    <wdx-app-sidebar (wdxOnCollapsed)="onCollapsed()">
        <wdx-nav-wrapper class="h-100">
            <wdx-nav-item class="overflow-auto">
                <a
                    wdxNavLink
                    [routerLink]="['/', tenant.code, HOME]"
                    routerLinkActive="active"
                >
                    <wdx-icon
                        class="me-2"
                        icon="home"
                        [scale]="2"
                        [fixedWidth]="true"
                    ></wdx-icon>
                    <span class="fs-5 fw-bold" *ngIf="!collapsed"> Home </span>
                </a>

                <a
                    wdxNavLink
                    *ngFor="let metadata of metadata$ | ngrxPush"
                    [routerLink]="['/', tenant.code, metadata.code]"
                    routerLinkActive="active"
                >
                    <wdx-icon
                        class="me-2"
                        [icon]="metadata.icon"
                        [scale]="2"
                        [fixedWidth]="true"
                    ></wdx-icon>
                    <span class="fs-5 fw-bold" *ngIf="!collapsed">
                        {{ metadata.pluralName }}
                    </span>
                </a>
            </wdx-nav-item>
        </wdx-nav-wrapper>
    </wdx-app-sidebar>

    <wdx-app-content>
        <div class="h-100 bg-body p-5">
            <router-outlet></router-outlet>
        </div>
    </wdx-app-content>
</wdx-app-shell>
