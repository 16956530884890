import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Output, inject } from '@angular/core';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { PushModule } from '@ngrx/component';
import {
    WdxButtonModule,
    WdxButtonStyle,
} from '@wdx/shared/components/wdx-button';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { WdxInfoCardModule } from '@wdx/shared/components/wdx-info-card';
import { FileSizePipe } from '@wdx/shared/utils';
import { NgxDropzoneChangeEvent, NgxDropzoneModule } from 'ngx-dropzone';

import { ImportEventType } from '@wdx/kamino/api-service';
import { KaminoModalWrapperComponent } from '@wdx/kamino/ui';
import { ICONS } from '@wdx/kamino/utils/constants';
import { WdxIsLoadingModule } from '@wdx/shared/components/wdx-is-loading';
import { KaminoFileUploaderService } from './file-uploader.service';

@Component({
    selector: 'kamino-file-uploader',
    standalone: true,
    imports: [
        KaminoModalWrapperComponent,
        NgxDropzoneModule,
        NgIf,
        NgFor,
        WdxInfoCardModule,
        WdxIconModule,
        WdxButtonModule,
        WdxIconContainerModule,
        FileSizePipe,
        PushModule,
        NgbProgressbarModule,
        WdxIsLoadingModule,
    ],
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
})
export class KaminoFileUploaderComponent {
    @Output() fileEvent = new EventEmitter();

    rejectedFiles = false;
    acceptedFiles!: File[];
    readonly WDX_BUTTON_STYLE = WdxButtonStyle;
    readonly ICONS = ICONS;

    public fileUploaderService = inject(KaminoFileUploaderService);

    onAddFiles(event: NgxDropzoneChangeEvent) {
        this.rejectedFiles = Boolean(event.rejectedFiles.length);
        this.acceptedFiles = event.addedFiles;

        if (event.rejectedFiles.length) {
            return;
        }

        try {
            this.fileUploaderService.addFiles(this.acceptedFiles);
        } catch (error) {
            console.error(error);
        }
    }

    onFileEvent(event: ImportEventType): void {
        this.fileEvent.emit(event);
    }
}
