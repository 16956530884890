import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export const MODAL_CONFIG: NgbModalOptions = {
    size: 'lg',
    backdropClass: 'modal-backdrop--ngb',
    modalDialogClass: 'modal-dialog-transition',
    animation: false,
    backdrop: 'static',
};

export const MODAL_CONFIG_FULLSCREEN: NgbModalOptions = {
    size: 'xl',
    backdropClass: 'modal-backdrop--ngb',
    modalDialogClass:
        'modal-dialog-transition modal-dialog-fullscreen modal-dialog-fullscreen--no-pd',
    animation: true,
    backdrop: 'static',
};
