import { Injectable, inject } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IImport } from '@wdx/kamino/api-service';
import { MODAL_CONFIG } from '@wdx/kamino/utils/constants';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { race } from 'rxjs/internal/observable/race';
import { take } from 'rxjs/operators';
import { KaminoFeaturesFileUploadComponent } from '../../../file-upload/file-upload.component';

@Injectable({
    providedIn: 'root',
})
export class KaminoFileUploaderService {
    private modalService = inject(NgbModal);
    activeModal!: NgbModalRef;

    private _uploadedFile$ = new BehaviorSubject<IImport | null>(null);

    /**
     * @return {BehaviorSubject<IImport | null>} uploadedFile$
     */
    get uploadedFile$(): Observable<IImport | null> {
        return this._uploadedFile$.asObservable();
    }
    /**
     * @return {IImport | null} uploadedFile$
     */
    get uploadedFile(): IImport | null {
        return this._uploadedFile$.value;
    }

    private _files$ = new Subject<File[]>();
    /**
     * @return {Subject<File[]>} files$
     */
    get files$(): Observable<File[]> {
        return this._files$.asObservable();
    }

    private _errorUploadingFile$ = new BehaviorSubject<Error | null>(null);
    /**
     * @return {Observable<Error | null>>} errorUploadingFile$
     */
    get errorUploadingFile$(): Observable<Error | null> {
        return this._errorUploadingFile$.asObservable();
    }

    private _uploadProgress$ = new BehaviorSubject(0);
    /**
     * This is the percentage of uploading the file
     * @return {Observable<number>>} uploadProgress$
     */
    get uploadProgress$(): Observable<number> {
        return this._uploadProgress$.asObservable();
    }

    private _disabled$ = new BehaviorSubject<boolean>(false);
    /**
     * This is if the file selector component should be disabled
     * @return {Observable<boolean>>} disabled$
     */
    get disabled$(): Observable<boolean> {
        return this._disabled$.asObservable();
    }

    private _fileEvent$ = new BehaviorSubject<boolean>(false);
    /**
     * This is if the file selector component should be disabled
     * @return {Observable<boolean>>} disabled$
     */
    get fileEvent$(): Observable<boolean> {
        return this._fileEvent$.asObservable();
    }

    /**
     * This will open the file uploader in a modal
     */
    open(): void {
        this.activeModal = this.modalService.open(
            KaminoFeaturesFileUploadComponent,
            MODAL_CONFIG
        );

        race(this.activeModal.closed, this.activeModal.dismissed)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.setUploadProgress(0);
                },
            });
    }

    /**
     * This will dismiss the reference modal
     */
    dismiss(): void {
        this.activeModal.dismiss();
    }

    addFiles(files: File[]): void {
        this._files$.next(files);
    }

    /**
     * This sets uploadedFile$ which is successfully uploaded file
     */
    setUploadedFile(file: IImport): void {
        this._uploadedFile$.next(file);
    }

    /**
     * Sets uploadProgress$ which is the percentage completed for an upload in progress
     */
    setUploadProgress(progress: number): void {
        this._uploadProgress$.next(progress);
    }

    /**
     * Sets errorUploadingFile$ when an error occurs uploading a file
     */
    addError(error: Error) {
        this._errorUploadingFile$.next(error);
    }

    /**
     * Sets disabled$ that is used to disabled the file selector
     */
    setDisabled(isDisabled: boolean) {
        this._disabled$.next(isDisabled);
    }

    /**
     * Sets fileEvent$ that is used to disabled the action buttons
     */
    setFiledEvent(eventInProgress: boolean) {
        this._fileEvent$.next(eventInProgress);
    }
}
