<div class="d-flex align-items-center">
    <wdx-dropdown ngbDropdown display="dynamic" #dropdown="ngbDropdown">
        <button
            ngbDropdownToggle
            data-cy="wdx-user-menu-button-toggle"
            class="btn fw-normal d-flex align-items-center text-primary-contrast"
        >
            <wdx-avatar
                data-cy="wdx-user-menu-avatar-toggle"
                *ngIf="avatar"
                [avatar]="avatar"
            ></wdx-avatar>

            <wdx-icon
                class="ms-2 toggle-icon"
                icon="chevron-down"
                [rotate]="dropdown.isOpen() ? 180 : 0"
            ></wdx-icon>
        </button>

        <wdx-dropdown-menu ngbDropdownMenu>
            <div
                data-cy="wdx-user-menu-container"
                class="d-flex flex-column align-items-center justify-content-center py-4 border-bottom"
            >
                <wdx-avatar
                    size="xl"
                    *ngIf="avatar"
                    [avatar]="avatar"
                ></wdx-avatar>
                <span class="fw-bold mt-2" [textContent]="avatar.text"></span>
            </div>

            <ng-content></ng-content>

            <button
                class="btn fw-normal py-2 w-100 text-start"
                data-cy="wdx-user-menu-logout"
                (click)="logout()"
            >
                <wdx-icon
                    class="me-1"
                    icon="right-from-bracket"
                    [fixedWidth]="true"
                ></wdx-icon>
                Log out
            </button>
        </wdx-dropdown-menu>
    </wdx-dropdown>
</div>
